import { InputAdornment, TextField } from "@mui/material";
import { Column } from "@tanstack/react-table";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";

const TextFilter: React.FC<{ column: Column<any, string> }> = ({ column }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const debouncedSetFilterValue = useDebounce(() => column.setFilterValue(inputRef.current?.value), 200);
  const filterValue = column.getFilterValue();
  const [value, setValue] = React.useState(filterValue || "");
  // When the value is updated outside the component, we want the field to represent that
  React.useEffect(() => {
    setValue(() => filterValue || "");
  }, [filterValue]);
  return (
    <TextField
      onChange={(e) => {
        debouncedSetFilterValue();
        setValue(e.target.value);
      }}
      placeholder="filter"
      onClick={(e) => e.stopPropagation()}
      value={value || ""}
      fullWidth
      InputProps={{
        inputRef: inputRef,
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={() => {
              setValue("");
              column.setFilterValue("");
            }}
            title="Reset filter"
          >
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon="times" />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default TextFilter;
